export const homepageBaseline = {
  section_one_title: 'Ready to get out on track?',
  section_one_subtitle: 'Our arrive and drive karting sessions are the perfect starting point. With approximately 13 minutes of on-track time per session, you will undoubtedly improve your lap times with each session!',
  section_two_title: 'Arrive & Drive',
  section_two_subtitle: 'Not sure where to start? Our arrive and drive karting sessions are the perfect starting point to!',
  racing_options_title: 'Looking for more racing options?',
  racing_options_one_title: 'League Racing',
  racing_options_one_subtitle: 'Ready for the next level? Saturday morning’s rental kart league racing makes the perfect progression from drop-in open session racing to organized, competitive, gridded up racing.',
  racing_options_one_icon: 'TrophyIcon',
  racing_options_two_title: 'Endurance Racing',
  racing_options_two_subtitle: 'You may have what it takes to put down a fast lap in one of PGP Motorsports Park’s Birel rental karts, but can you do it lap after lap after lap?',
  racing_options_two_icon: 'ClockIcon',
  racing_options_three_title: 'Group Events',
  racing_options_three_subtitle: 'PGP Motorsports Park offers an exciting alternative to the usual party.',
  racing_options_three_icon: 'UserGroupIcon',
  schedule_title: 'What\'s happening at PGP',
  schedule_subtitle: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in, accusamus quisquam.',
  queryForImageByKey: (obj, key) => {
    return obj[key] ? `${obj[key].data.attributes.hash}${obj[key].data.attributes.ext}` : 'placeholder_udwi8w.png'    
  }
}

export const pricingPageBaseline = {
  title: 'Pricing',
  subtitle_one: 'Find the right option for you, whoever you are',
  subtitle_two: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit numquam eligendi quos odit doloribus molestiae voluptatum.',
  requirement_two: '- Any Drivers under the age of 18 years old must have **their parent or LEGAL guardian present at the track in order to sign the waiver.** (Drivers may be required to show I.D. for proof of age)',
  requirement_one: '- Minimum age is 14 yrs. Exceptions made for 10-13yr. olds who have successfully completed the PGP Junior Racer course. Call to schedule your Junior Racer class today!',
  requirement_three: '- All drivers MUST wear closed-toe shoes',
  mainPricingPage: [
    {
      id: 2,
      description: 'Ready to get out on track, but not sure where to start? Our arrive and drive karting sessions are the perfect starting point.',
      session_name: 'Arrive and Drive - Non Member',
      features: '$26 each additional session, same day;\n' +
        '13 total minutes of on track time;\n' +
        '$3 head sock fee, if using our helmet, otherwise you can use your own DOT full face helmet.;\n' +
        'Note: Minimum age is 14yrs. for all rental racing.;\n' +
        'Drivers 14-17 years of age must have THEIR parent present at the track the entire time.',
      pricing_model: 'session',
      price: '38'
    },
    {
      id: 4,
      description: 'Ready to get out on track, but not sure where to start? Our arrive and drive karting sessions are the perfect starting point.',
      session_name: 'Arrive and Drive - Member',
      features: '13 total minutes of on track time;\n' +
        '$3 head sock fee, if using our helmet, otherwise you can use your own DOT full face helmet.;\n' +
        'Note: Minimum age is 14yrs. for all rental racing.;\n' +
        'Drivers 14-17 years of age must have THEIR parent present at the track the entire time.',
      pricing_model: 'session',
      price: '26'
    }
  ],
  additionalOptions: [
    {
      id: 3,
      description: null,
      session_name: 'PGP Membership',
      features: 'Annual Membership pays for itself in no time with all the benefits and discounts!;\n' +
        '$12 discount on the first session, each day (Regular price $38);\n' +
        '$10 discount on every League Race;\n' +
        'Free rental session every 10 sessions (10 PGP Rewards points);\n' +
        'Special discounts and offers for Members only throughout the year;\n' +
        'Memberships valid for 12 months from date of purchase so you receive year-round discounts!',
      pricing_model: 'year',
      price: '50'
    },
    {
      id: 5,
      description: 'Saturday Morning’s, our popular Rental Kart League Racing makes the perfect progression from drop-in open session racing to organized, competitive, gridded up racing',
      session_name: 'League Racing',
      features: 'Racers with an active PGP membership receive a $10 discount;\n' +
        'Four on track sessions;\n' +
        'Classes are divided into two divisions: Pro-Am and Pro.;\n' +
        'Trophies are awarded each race to the top 3 in each class.;\n' +
        '$3 head sock fee, if using our helmet, otherwise you can use your own DOT full face helmet.;',
      pricing_model: 'event',
      price: '95'
    },
    {
      id: 7,
      description: null,
      session_name: 'Endurance Racing - Solo',
      features: 'Single Racer competing for 90 minutes!;\n' +
        'Racers with an active PGP membership receive a $10 discount;\n' +
        '$3 head sock fee, if using our helmet, otherwise you can use your own DOT full face helmet.',
      pricing_model: 'event',
      price: '135'
    },
    {
      id: 8,
      description: null,
      session_name: 'Endurance Race - Two Person Team',
      features: 'Two person team competing for 90 minutes!;\n' +
        'Racers with an active PGP membership receive a $10 discount;\n' +
        '$3 head sock fee, if using our helmet, otherwise you can use your own DOT full face helmet.',
      pricing_model: 'person',
      price: '125'
    },
    {
      id: 9,
      description: null,
      session_name: 'Endurance Race - Three Person Team',
      features: 'Three person team competing for 90 minutes!;\n' +
        'Racers with an active PGP membership receive a $10 discount;\n' +
        '$3 head sock fee, if using our helmet, otherwise you can use your own DOT full face helmet.',
      pricing_model: 'person',
      price: '85'
    },
    {
      id: 10,
      description: null,
      session_name: 'Four Stroke Racing - Race Entry',
      features: 'Race Entry includes driver’s pit pass;\n' +
        'Pit Pass: $10.00 (required for all non-drivers in the paved paddock);\n' +
        'Optional Transponder Rental $10.00',
      pricing_model: 'person',
      price: '95'
    },
    {
      id: 11,
      description: null,
      session_name: 'Four Stroke Racing - Weekday Practice',
      features: 'Weekday Practice;\n' +
        '9:00am-4:30pm, by appointment only;\n' +
        'Optional Transponder Rental $10.00',
      pricing_model: 'person',
      price: '60'
    }
  ]
}

export const groupEventsBaseLine = {
  title: 'An exciting alternative to the usual party',
  subtitle: 'Whatever the occasion, whatever the size, our event coordinators will utilize our state-of-the-art karting facility to personally customize your event to create the day you’ve envisioned.',
  title_two: 'Corporate Events',
  description_two: 'PGP Corporate and Team Building Events are the perfect solution for companies looking to inspire their employees and colleagues with an exhilarating, high-performance and unforgettable experience. In addition to providing all of the standard event amenities, from racing gear to complete catering options, PGP Motorsports Park delivers exciting and bond-building racing challenges that make any event a tremendous success.\n\n\nA PGP corporate racing event takes center stage in providing participants with a truly memorable experience. There are a variety of racing packages to choose from that ensure the event is perfectly tailored to your groups’ specific needs and goals. PGP’s expert event staff is happy to customize an event just for your group to meet your goals and expectations for the day.\n\n\nPGP’s group events come complete with race instruction, timing & scoring sheets, use of awards podium for your own ceremony, tables with umbrellas, chairs, gas barbeque, and of course all the race gear needed for rain or shine racing. If your group would like to bring in your own picnic meal or barbecue, to round out your race day, we encourage it so you can spend even more time at the park. Let us know your needs and we are happy to assist with the details to ensure your guests thoroughly enjoy their experience.\n\n\nAll of our corporate events at PGP are custom built to suit the needs of your group. Just let us know the size of your group and our event coordinators will craft a number of options that are sure to be the perfect fit for your company, club or any large group.\n\n\nTo help us get you starting planning your next successful event, give us a call at [253-639-7223](tel:2536397233) or email us at [info@pacificgp.com](mailto:info@pacificgp.com). One of our event coordinators will reach out to you to get your group on track at PGP!',
  event_min_amount: '40 Person minimum',
  title_three: 'PGP Motorsports Park is the perfect venue for your:',
  description_three: 'Included in PGP’s group events are the use of our propane barbeque, picnic tables and umbrellas, use of the awards podium for your own fun awards presentation and all the race gear needed for either rain or shine racing. Optional trophies may be purchased to highlight your top winners on the track. You are welcome to bring in your own food to enjoy a picnic style meal and we encourage your group to stay as long as you wish. PGP staff are experts in group events and will do everything to have your day run effortlessly and ensure a fantastic time for everyone.\n\nA PGP group event ensures you and your guests will have your own personal track sessions complete with timing result printouts from each race. Results include fastest lap times for bragging rights!',
  highlights: [
      {
          id: 1,
          bold_title: 'An Event to Remember',
          description: 'PGP is the ultimate location for fun and exciting parties and will be an event your guests will truly remember as the awesome event!'
      },
      {
          id: 2,
          bold_title: 'Multiple Options',
          description: 'To make your event planning even easier, PGP has crafted three levels of events to suit most groups from 10-40 racers.'
      },
      {
          id: 3,
          bold_title: 'Extra Track Time',
          description: 'For all of you hardcore racers that crave more track time, we can add any number of extra sessions to your group event for $25/session per racer.'
      }
  ],
  options: [
    {
        id: 1,
        title: 'Group Event - Shootout',
        description: 'The Shootout is a event consisting of two 13 minute on track sessions for a total of 26 minutes on track time and requires approximately 75 minutes to complete',
        price_per_person: 60,
        features: 'Pre-Race briefing;Time full practice;\nFinal race;\n10 People minimum'
    },
    {
        id: 2,
        title: 'Group Event - Mini Grand Prix',
        description: 'The Mini Grand Prix is by far our most popular event. Consisting of three 13 minute on track sessions and requiring approximately 90 minutes total to complete.',
        price_per_person: 85,
        features: 'Pre-Race briefing;\nTimed full practice;\nTimed Qualifying session\nFinal race;\n10 People minimum'
    },
    {
        id: 3,
        title: 'Group Event - Full Grand Prix',
        description: 'The Full Grand Prix provide almost a full hour of on track racing action in four 13 minute sessions. Requires approximately 2 hours to complete.',
        price_per_person: 110,
        features: 'Pre-Race briefing;\nTimed full practice;\nTimed Qualifying session;\nSemifinal race;\nFinal race;\n10 People minimum'
    }
  ],
  queryForImageByKey: (obj, key, nested = false) => {
    if (nested) {
      return obj.image.data ? `${obj.image.data.attributes.hash}${obj.image.data.attributes.ext}` : 'placeholder_udwi8w.png'
    } else {
      return obj[key] ? `${obj[key].data.attributes.hash}${obj[key].data.attributes.ext}` : 'placeholder_udwi8w.png'
    }
  }
}

export const trackBaseLine = {
  title: 'Track',
  description: 'PGP Motorsports Park has one of the most unique settings in all of North America. The 22-acre site had 1.6 million cubic yards of dirt removed from it and what was created was a natural velodrome with unprecedented viewing for our spectators. There is no one part of the circuit that cannot be seen and the overhead view looking down allows for spectators to see all the inside-out action in the corners. Much better visibility than your typical “on-plane” views at most other tracks.',
  description_two: 'With up to 15 turns, the layout is designed so that horsepower can be an advantage at the south end; longer straights and hard braking corners allowing for maximum passing zones. But the north end is very technical rewarding the pilots with the best driving skills.',
  description_three: 'PGP Motorsports Park has lots of subtle elevation changes and unique characteristics combined with multiple configurations. Arrive and Drives, Corporate Entertainment, 4- Stroke Karting Practice Days… you name it, we have you covered!',
  description_four: 'PGP Motorsports Park has also been designed so that multiple activities can take place at any one time with events running simultaneously on both the North and South Sprint Courses.',
  track_corners: [
    {
      name: 'The Roller Coaster',
      description:
        'Late apex and stay mid-track on the exit of the first turn. Stay tight inside on the second curb. Ideally you want to mid-apex the second and third turns in this series.'
    },
    {
      name: 'The Lightbulb',
      description:
        'Use light braking on entry and position yourself for a late-apex into this decreasing radius corner. Maintaining good exit speed is crucial.'
    },
    {
      name: 'Top of the World',
      description:
        'Take as straight of a line as possible. Stay tight to the curbs and stay on the throttle throughout.'
    },
    {
      name: 'The Bus Stop',
      description:
        'Take as straight of a line as possible. Stay tight to the curbs and stay on the throttle throughout.'
    },
    {
      name: 'Turn 7 and 6',
      description:
        'Be hard on the brakes going in. Stay tight on the exit of turn 7 to set up for a mid-apex on turn 6.'
    },
    {
      name: 'Turn 5 and 4',
      description:
        'Be light on the brakes into turn 5. Stay tight inside on the exit in order to late apex turn 4. This is crucial for momentum onto the back straightaway.'
    },
    {
      name: 'Turn 3',
      description:
        'Utilize hard braking before entry. Pick a braking mark and stick with it. Be quick back to the throttle with a semi-late apex.'
    },
    {
      name: 'Turn 2',
      description:
        'Lift on entry. Ignore the first apex curb and stay mid-track. Focus should be on a late apex on the second set of curbing. Hug the inside on exit.'
    },
    {
      name: 'Turn 1',
      description:
        'Start as wide as possible. Tiny lift before turn-in. Focus on a semi-late apex.'
    },
  ],
  queryForImageByKey: (obj, key, nested = false) => {
    if (nested) {
      return obj.image.data ? `${obj.image.data.attributes.hash}${obj.image.data.attributes.ext}` : 'placeholder_udwi8w.png'
    } else {
      return obj[key] ? `${obj[key].data.attributes.hash}${obj[key].data.attributes.ext}` : 'placeholder_udwi8w.png'
    }
  }
}

export const fourStrokeBaseLine = {
  title: '4-stroke racing',
  description: 'The 4-Stroke Racing Series at PGP continues to expand and push for cost effective ways for drivers wishing to own their own equipment be able to race competitively at our state of the art track. As with all of the racing at PGP, our 4-Stroke racing runs rain or shine.',
  description_two: 'Ready to get out on track?',
  description_three: 'The 4 Stroke Racing Series is run as a stand-alone event on Sunday mornings. Racing divisions include the following:',
  description_four: 'The popular L.O. 206 will run as a Senior division at 360lbs and a Heavy Division at 390lbs.',
  description_five: 'For the 2021/22 season we will be adding the Tillotson 225 engine as a standalone category. This category will run at 390 lbs.',
  race_day: '',
  queryForImageByKey: (obj, key, nested = false) => {
    if (nested) {
      return obj.image.data ? `${obj.image.data.attributes.hash}${obj.image.data.attributes.ext}` : 'placeholder_udwi8w.png'
    } else {
      return obj[key] ? `${obj[key].data.attributes.hash}${obj[key].data.attributes.ext}` : 'placeholder_udwi8w.png'
    }
  }
}

export const schoolBaseLine = {
  title: 'Race School',
  description: 'Take your karting skills to the next level!',
  options: [
    {
        id: 1,
        title: 'Youth Racing School',
        description: 'The Junior Race Academy program at PGP is a curriculum designed to safely introduce young drivers to kart racing.  This course is a pre-requisite for any drivers between the ages of 10-13 (minimum age is 10 years old) who wish to participate in the Arrive and Drive Sessions. This class focuses:',
        description_two: 'The academy program is a 2 hour long program with each driver receiving classroom instruction followed by four on-track racing sessions. Young racers achieving a lap time of 94 seconds or faster during the academy will be granted an age exemption to participate in the Arrive and Drive sessions (with a parent present).',
        description_three: 'The Junior Race Academy program will be taught on Sunday mornings at 8:30am and booked by appointment only. Give us a call today to get your young driver on the list and out on track at PGP!',
        price_per_person: 149,
        features: 'driving safety,;flags;\nrules;\nracing etiquette;\nbasic driving technique'
    },
    {
        id: 2,
        title: 'Adult Racing School',
        description: 'Whether you are a newcomer to karting or a seasoned PGP veteran, our private adult instruction courses are sure to bring speed to your laptimes and edge to your racecraft. In addition to classroom instruction, students will walk critical sections of the track with their instructor and participate in 4 on-track driving sessions. This class will cover:',
        description_two: 'Our Adult Karting Courses are tailored to the needs of each individual student. PGP instructors will review your racing history and build an instruction session to most effectively benefit your driving goals.',
        description_three: 'All adult instruction at PGP is taught with a one student to one instructor ratio to maximize the impact on our driving performance. These courses generally require roughly 2 hours to complete and are all taught by appointment.',
        price_per_person: 149,
        features: 'kart basics;\n general instruction;\n how to “read” the track and racing lines;\nan emphasis on smooth driving'
    }
  ],
  queryForImageByKey: (obj, key, nested = false) => {
    if (nested) {
      return obj.image.data ? `${obj.image.data.attributes.hash}${obj.image.data.attributes.ext}` : 'placeholder_udwi8w.png'
    } else {
      return obj[key] ? `${obj[key].data.attributes.hash}${obj[key].data.attributes.ext}` : 'placeholder_udwi8w.png'
    }
  }
}

export const racingBaseLine = {
  title: 'Rental Racing',
  description: 'The only thing you\'ll need is a ride to PGP!',
  description_two: 'PGP Motorsports Park utilizes a fleet of 30 Italian-built Birel N35 rental karts. The N35 karts are equipped with 9hp Honda 4-Stroke engines, to propel drivers at up to 50mph around our .82 Mile purpose built race track.',
  title_two: 'A lap around PGP',
  title_three: 'Racing options',
  title_four: 'Current schedule',
  kart_features: [],
  requirement_two: '- Any Drivers under the age of 18 years old must have **their parent or LEGAL guardian present at the track in order to sign the waiver.** (Drivers may be required to show I.D. for proof of age)',
  requirement_one: '- Minimum age is 14 yrs. Exceptions made for 10-13yr. olds who have successfully completed the PGP Junior Racer course. Call to schedule your Junior Racer class today!',
  requirement_three: '- All drivers MUST wear closed-toe shoes',
  racing_formats: [
    {
        id: 1,
        title: 'Youth Racing School',
        description: 'The Junior Race Academy program at PGP is a curriculum designed to safely introduce young drivers to kart racing.  This course is a pre-requisite for any drivers between the ages of 10-13 (minimum age is 10 years old) who wish to participate in the Arrive and Drive Sessions. This class focuses:',
        description_two: 'The academy program is a 2 hour long program with each driver receiving classroom instruction followed by four on-track racing sessions. Young racers achieving a lap time of 94 seconds or faster during the academy will be granted an age exemption to participate in the Arrive and Drive sessions (with a parent present).',
        description_three: 'The Junior Race Academy program will be taught on Sunday mornings at 8:30am and booked by appointment only. Give us a call today to get your young driver on the list and out on track at PGP!',
        price_per_person: 149,
        features: 'driving safety,;flags;\nrules;\nracing etiquette;\nbasic driving technique'
    },
    {
        id: 2,
        title: 'Adult Racing School',
        description: 'Whether you are a newcomer to karting or a seasoned PGP veteran, our private adult instruction courses are sure to bring speed to your laptimes and edge to your racecraft. In addition to classroom instruction, students will walk critical sections of the track with their instructor and participate in 4 on-track driving sessions. This class will cover:',
        description_two: 'Our Adult Karting Courses are tailored to the needs of each individual student. PGP instructors will review your racing history and build an instruction session to most effectively benefit your driving goals.',
        description_three: 'All adult instruction at PGP is taught with a one student to one instructor ratio to maximize the impact on our driving performance. These courses generally require roughly 2 hours to complete and are all taught by appointment.',
        price_per_person: 149,
        features: 'kart basics;\n general instruction;\n how to “read” the track and racing lines;\nan emphasis on smooth driving'
    }
  ],
  queryForImageByKey: (obj, key, nested = false) => {
    if (nested) {
      return obj.image.data ? `${obj.image.data.attributes.hash}${obj.image.data.attributes.ext}` : 'placeholder_udwi8w.png'
    } else {
      return obj[key] ? `${obj[key].data.attributes.hash}${obj[key].data.attributes.ext}` : 'placeholder_udwi8w.png'
    }
  }
}